<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">{{ $t('Auth.Title.ModifyPassword') }}</h2>
        <div class="" style="margin: 52px 0">
            <el-form label-position="left" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px">
                <el-form-item :label="$t('Auth.Form.OldPassword')" prop="oldPassword">
                    <el-input type="password" v-model="ruleForm.oldPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.NewPassword')" prop="password">
                    <el-input type="password" v-model="ruleForm.password" autocomplete="off" :placeholder="$t('Auth.Form.PasswordPlaceholder')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.ConfirmNewPassword')" prop="password_confirmation">
                    <el-input type="password" v-model="ruleForm.password_confirmation" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 32px" >
                    <el-button class="submit-btn" type="primary" @click="submitForm('ruleForm')">{{ $t('Auth.Form.BtnModify') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModifyPassword',
        inject: ['reload'],
        data() {
            var validateOldPassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    // if (this.ruleForm.checkPass !== '') {
                    //     this.$refs.ruleForm.validateField('checkPass');
                    // }
                    callback();
                }
            };
            var validatePassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else {
                    callback();
                }
            };
            var validatePassword_ = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(' '));
                } else if (value !== this.ruleForm.password) {
                    callback(new Error(this.$t('Auth.Hint.S1')));
                } else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    oldPassword: '',
                    password: '',
                    password_confirmation: '',
                },
                rules: {
                    oldPassword: [
                        { validator: validateOldPassword, trigger: 'blur' }
                    ],
                    password: [
                        { validator: validatePassword, trigger: 'blur' }
                    ],
                    password_confirmation: [
                        { validator: validatePassword_, trigger: 'blur' }
                    ],
                }
            };
        },
        mounted () {
            // console.log(this.$router)
        },
        methods: {
            submitForm(formName) {
                // this.$message.error('dasdasdsa')
                this.$refs[formName].validate((valid) => {
                    if (valid) {

                        this.$axios.post('/modifyPassword', {
                            ...this.ruleForm,
                            // oldPassword: this.ruleForm.oldPassword,
                            // password: this.ruleForm.password,
                            // password_confirmation: this.ruleForm.password_confirmation,
                        }).then(res => {
                            // this.$router.push({path: '/console'})
                            this.$message.success(this.$t('Hint.ModifySuccess'))
                            this.reload()
                        }, err => {})

                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .el-input {
        width: 320px;
    }
</style>