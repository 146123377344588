<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            {{ $t('Auth.Title.BaseInfo') }}
        </h2>
        <div style="margin: 52px 0" v-loading="loading">
            <el-form label-position="left" :model="form" status-icon ref="form" label-width="120px">
                <el-divider content-position="left">{{ $t('Auth.Form.UserInfo') }}</el-divider>
                <el-form-item :label="$t('Auth.Form.UserID')">
                    {{ form.userId }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.Username')">
                    {{ form.username }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.Mobile')">
                    {{ form.mobile }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.RegisteredAt')">
                    {{ form.registeredAt }}
                </el-form-item>
                <el-divider content-position="left">{{ $t('Auth.Form.CorpInfo') }}</el-divider>
                <el-form-item :label="$t('Auth.Form.CorpID')">
                    {{ form.corpCode }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.CorpName')">
                    {{ form.corpName }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.Contact')">
                    {{ form.contact }}
                </el-form-item>
                <el-form-item :label="$t('Auth.Form.Mobile')">
                    {{ form.contactMobile }}
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseInfo",
        data () {
            return {
                loading: true,
                form: {
                    username: '',
                    userId: '',
                    mobile: '',
                    registeredAt: '',
                    corpName: '',
                    corpCode: '',
                    contact: '',
                    contactMobile: '',
                }
            }
        },
        created() {
            this.$axios.get('/baseInfo')
                .then((res) => {
                    this.form.username = res.username
                    this.form.userId = res.userId
                    this.form.registeredAt = res.registeredAt
                    this.form.corpName = res.corpName
                    this.form.corpCode = res.corpCode
                    this.form.contact = res.contact
                    this.form.mobile = res.mobile
                    this.form.contactMobile = res.contactMobile
                    this.loading = false
                }, error => {})
        }
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 8px;
    }
</style>